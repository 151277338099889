// @ts-nocheck
import React from "react"
import useSWR, { useSWRConfig } from "swr"

const configJSON = require("./config")
const baseUrlConfig = require("../../../../framework/src/config")

export type withCartProps = {
  cartData: any
  cartLoading: boolean
  cartError: any
  mutateCart: (data: any) => void
  triggerCart: () => void
}

function withCart(OriginalComponent) {
  return (props) => {
    const { mutate: triggerMutate } = useSWRConfig()
    const fetcher = (url: string) => {
      return fetch(url.indexOf("://") === -1 ? baseUrlConfig.baseURL + "/" + url : url, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token") ?? "",
        },
      }).then((r) => r.json())
    }

    const { data: cart, mutate, error } = useSWR(configJSON.endPointApiGetIsCartCreated, fetcher, {
      dedupingInterval: 30000, focusThrottleInterval: 360000, revalidateOnFocus: false
    })

    const mutateCart = (mutatedData, validation = false) => {
      const newData = { data: [{ ...mutatedData }] }
      mutate(newData, { revalidate: false })
    }

    const triggerCart = () => {
      triggerMutate(configJSON.endPointApiGetIsCartCreated)
    }

    return (
      <OriginalComponent
        {...props}
        cartData={cart?.data?.[0]}
        cartLoading={!error && !cart}
        cartError={error}
        mutateCart={mutateCart}
        triggerCart={triggerCart}
      />
    )
  }
}
export default withCart
